var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};

/*
Stimulus 1.1.1
Copyright © 2019 Basecamp, LLC
 */
(function (global, factory) {
  factory(exports);
})(exports, function (exports) {
  "use strict";

  var EventListener = function () {
    function EventListener(eventTarget, eventName) {
      (this || _global).eventTarget = eventTarget;
      (this || _global).eventName = eventName;
      (this || _global).unorderedBindings = new Set();
    }

    EventListener.prototype.connect = function () {
      (this || _global).eventTarget.addEventListener((this || _global).eventName, this || _global, false);
    };

    EventListener.prototype.disconnect = function () {
      (this || _global).eventTarget.removeEventListener((this || _global).eventName, this || _global, false);
    };

    EventListener.prototype.bindingConnected = function (binding) {
      (this || _global).unorderedBindings.add(binding);
    };

    EventListener.prototype.bindingDisconnected = function (binding) {
      (this || _global).unorderedBindings.delete(binding);
    };

    EventListener.prototype.handleEvent = function (event) {
      var extendedEvent = extendEvent(event);

      for (var _i = 0, _a = (this || _global).bindings; _i < _a.length; _i++) {
        var binding = _a[_i];

        if (extendedEvent.immediatePropagationStopped) {
          break;
        } else {
          binding.handleEvent(extendedEvent);
        }
      }
    };

    Object.defineProperty(EventListener.prototype, "bindings", {
      get: function () {
        return Array.from((this || _global).unorderedBindings).sort(function (left, right) {
          var leftIndex = left.index,
              rightIndex = right.index;
          return leftIndex < rightIndex ? -1 : leftIndex > rightIndex ? 1 : 0;
        });
      },
      enumerable: true,
      configurable: true
    });
    return EventListener;
  }();

  function extendEvent(event) {
    if ("immediatePropagationStopped" in event) {
      return event;
    } else {
      var stopImmediatePropagation_1 = event.stopImmediatePropagation;
      return Object.assign(event, {
        immediatePropagationStopped: false,
        stopImmediatePropagation: function () {
          (this || _global).immediatePropagationStopped = true;
          stopImmediatePropagation_1.call(this || _global);
        }
      });
    }
  }

  var Dispatcher = function () {
    function Dispatcher(application) {
      (this || _global).application = application;
      (this || _global).eventListenerMaps = new Map();
      (this || _global).started = false;
    }

    Dispatcher.prototype.start = function () {
      if (!(this || _global).started) {
        (this || _global).started = true;

        (this || _global).eventListeners.forEach(function (eventListener) {
          return eventListener.connect();
        });
      }
    };

    Dispatcher.prototype.stop = function () {
      if ((this || _global).started) {
        (this || _global).started = false;

        (this || _global).eventListeners.forEach(function (eventListener) {
          return eventListener.disconnect();
        });
      }
    };

    Object.defineProperty(Dispatcher.prototype, "eventListeners", {
      get: function () {
        return Array.from((this || _global).eventListenerMaps.values()).reduce(function (listeners, map) {
          return listeners.concat(Array.from(map.values()));
        }, []);
      },
      enumerable: true,
      configurable: true
    });

    Dispatcher.prototype.bindingConnected = function (binding) {
      this.fetchEventListenerForBinding(binding).bindingConnected(binding);
    };

    Dispatcher.prototype.bindingDisconnected = function (binding) {
      this.fetchEventListenerForBinding(binding).bindingDisconnected(binding);
    };

    Dispatcher.prototype.handleError = function (error, message, detail) {
      if (detail === void 0) {
        detail = {};
      }

      (this || _global).application.handleError(error, "Error " + message, detail);
    };

    Dispatcher.prototype.fetchEventListenerForBinding = function (binding) {
      var eventTarget = binding.eventTarget,
          eventName = binding.eventName;
      return this.fetchEventListener(eventTarget, eventName);
    };

    Dispatcher.prototype.fetchEventListener = function (eventTarget, eventName) {
      var eventListenerMap = this.fetchEventListenerMapForEventTarget(eventTarget);
      var eventListener = eventListenerMap.get(eventName);

      if (!eventListener) {
        eventListener = this.createEventListener(eventTarget, eventName);
        eventListenerMap.set(eventName, eventListener);
      }

      return eventListener;
    };

    Dispatcher.prototype.createEventListener = function (eventTarget, eventName) {
      var eventListener = new EventListener(eventTarget, eventName);

      if ((this || _global).started) {
        eventListener.connect();
      }

      return eventListener;
    };

    Dispatcher.prototype.fetchEventListenerMapForEventTarget = function (eventTarget) {
      var eventListenerMap = (this || _global).eventListenerMaps.get(eventTarget);

      if (!eventListenerMap) {
        eventListenerMap = new Map();

        (this || _global).eventListenerMaps.set(eventTarget, eventListenerMap);
      }

      return eventListenerMap;
    };

    return Dispatcher;
  }();

  var descriptorPattern = /^((.+?)(@(window|document))?->)?(.+?)(#(.+))?$/;

  function parseDescriptorString(descriptorString) {
    var source = descriptorString.trim();
    var matches = source.match(descriptorPattern) || [];
    return {
      eventTarget: parseEventTarget(matches[4]),
      eventName: matches[2],
      identifier: matches[5],
      methodName: matches[7]
    };
  }

  function parseEventTarget(eventTargetName) {
    if (eventTargetName == "window") {
      return window;
    } else if (eventTargetName == "document") {
      return document;
    }
  }

  function stringifyEventTarget(eventTarget) {
    if (eventTarget == window) {
      return "window";
    } else if (eventTarget == document) {
      return "document";
    }
  }

  var Action = function () {
    function Action(element, index, descriptor) {
      (this || _global).element = element;
      (this || _global).index = index;
      (this || _global).eventTarget = descriptor.eventTarget || element;
      (this || _global).eventName = descriptor.eventName || getDefaultEventNameForElement(element) || error("missing event name");
      (this || _global).identifier = descriptor.identifier || error("missing identifier");
      (this || _global).methodName = descriptor.methodName || error("missing method name");
    }

    Action.forToken = function (token) {
      return new (this || _global)(token.element, token.index, parseDescriptorString(token.content));
    };

    Action.prototype.toString = function () {
      var eventNameSuffix = (this || _global).eventTargetName ? "@" + (this || _global).eventTargetName : "";
      return "" + (this || _global).eventName + eventNameSuffix + "->" + (this || _global).identifier + "#" + (this || _global).methodName;
    };

    Object.defineProperty(Action.prototype, "eventTargetName", {
      get: function () {
        return stringifyEventTarget((this || _global).eventTarget);
      },
      enumerable: true,
      configurable: true
    });
    return Action;
  }();

  var defaultEventNames = {
    a: function (e) {
      return "click";
    },
    button: function (e) {
      return "click";
    },
    form: function (e) {
      return "submit";
    },
    input: function (e) {
      return e.getAttribute("type") == "submit" ? "click" : "change";
    },
    select: function (e) {
      return "change";
    },
    textarea: function (e) {
      return "change";
    }
  };

  function getDefaultEventNameForElement(element) {
    var tagName = element.tagName.toLowerCase();

    if (tagName in defaultEventNames) {
      return defaultEventNames[tagName](element);
    }
  }

  function error(message) {
    throw new Error(message);
  }

  var Binding = function () {
    function Binding(context, action) {
      (this || _global).context = context;
      (this || _global).action = action;
    }

    Object.defineProperty(Binding.prototype, "index", {
      get: function () {
        return (this || _global).action.index;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Binding.prototype, "eventTarget", {
      get: function () {
        return (this || _global).action.eventTarget;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Binding.prototype, "identifier", {
      get: function () {
        return (this || _global).context.identifier;
      },
      enumerable: true,
      configurable: true
    });

    Binding.prototype.handleEvent = function (event) {
      if (this.willBeInvokedByEvent(event)) {
        this.invokeWithEvent(event);
      }
    };

    Object.defineProperty(Binding.prototype, "eventName", {
      get: function () {
        return (this || _global).action.eventName;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Binding.prototype, "method", {
      get: function () {
        var method = (this || _global).controller[(this || _global).methodName];

        if (typeof method == "function") {
          return method;
        }

        throw new Error("Action \"" + (this || _global).action + "\" references undefined method \"" + (this || _global).methodName + "\"");
      },
      enumerable: true,
      configurable: true
    });

    Binding.prototype.invokeWithEvent = function (event) {
      try {
        (this || _global).method.call((this || _global).controller, event);
      } catch (error) {
        var _a = this || _global,
            identifier = _a.identifier,
            controller = _a.controller,
            element = _a.element,
            index = _a.index;

        var detail = {
          identifier: identifier,
          controller: controller,
          element: element,
          index: index,
          event: event
        };

        (this || _global).context.handleError(error, "invoking action \"" + (this || _global).action + "\"", detail);
      }
    };

    Binding.prototype.willBeInvokedByEvent = function (event) {
      var eventTarget = event.target;

      if ((this || _global).element === eventTarget) {
        return true;
      } else if (eventTarget instanceof Element && (this || _global).element.contains(eventTarget)) {
        return (this || _global).scope.containsElement(eventTarget);
      } else {
        return true;
      }
    };

    Object.defineProperty(Binding.prototype, "controller", {
      get: function () {
        return (this || _global).context.controller;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Binding.prototype, "methodName", {
      get: function () {
        return (this || _global).action.methodName;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Binding.prototype, "element", {
      get: function () {
        return (this || _global).scope.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Binding.prototype, "scope", {
      get: function () {
        return (this || _global).context.scope;
      },
      enumerable: true,
      configurable: true
    });
    return Binding;
  }();

  var ElementObserver = function () {
    function ElementObserver(element, delegate) {
      var _this = this || _global;

      (this || _global).element = element;
      (this || _global).started = false;
      (this || _global).delegate = delegate;
      (this || _global).elements = new Set();
      (this || _global).mutationObserver = new MutationObserver(function (mutations) {
        return _this.processMutations(mutations);
      });
    }

    ElementObserver.prototype.start = function () {
      if (!(this || _global).started) {
        (this || _global).started = true;

        (this || _global).mutationObserver.observe((this || _global).element, {
          attributes: true,
          childList: true,
          subtree: true
        });

        this.refresh();
      }
    };

    ElementObserver.prototype.stop = function () {
      if ((this || _global).started) {
        (this || _global).mutationObserver.takeRecords();

        (this || _global).mutationObserver.disconnect();

        (this || _global).started = false;
      }
    };

    ElementObserver.prototype.refresh = function () {
      if ((this || _global).started) {
        var matches = new Set(this.matchElementsInTree());

        for (var _i = 0, _a = Array.from((this || _global).elements); _i < _a.length; _i++) {
          var element = _a[_i];

          if (!matches.has(element)) {
            this.removeElement(element);
          }
        }

        for (var _b = 0, _c = Array.from(matches); _b < _c.length; _b++) {
          var element = _c[_b];
          this.addElement(element);
        }
      }
    };

    ElementObserver.prototype.processMutations = function (mutations) {
      if ((this || _global).started) {
        for (var _i = 0, mutations_1 = mutations; _i < mutations_1.length; _i++) {
          var mutation = mutations_1[_i];
          this.processMutation(mutation);
        }
      }
    };

    ElementObserver.prototype.processMutation = function (mutation) {
      if (mutation.type == "attributes") {
        this.processAttributeChange(mutation.target, mutation.attributeName);
      } else if (mutation.type == "childList") {
        this.processRemovedNodes(mutation.removedNodes);
        this.processAddedNodes(mutation.addedNodes);
      }
    };

    ElementObserver.prototype.processAttributeChange = function (node, attributeName) {
      var element = node;

      if ((this || _global).elements.has(element)) {
        if ((this || _global).delegate.elementAttributeChanged && this.matchElement(element)) {
          (this || _global).delegate.elementAttributeChanged(element, attributeName);
        } else {
          this.removeElement(element);
        }
      } else if (this.matchElement(element)) {
        this.addElement(element);
      }
    };

    ElementObserver.prototype.processRemovedNodes = function (nodes) {
      for (var _i = 0, _a = Array.from(nodes); _i < _a.length; _i++) {
        var node = _a[_i];
        var element = this.elementFromNode(node);

        if (element) {
          this.processTree(element, (this || _global).removeElement);
        }
      }
    };

    ElementObserver.prototype.processAddedNodes = function (nodes) {
      for (var _i = 0, _a = Array.from(nodes); _i < _a.length; _i++) {
        var node = _a[_i];
        var element = this.elementFromNode(node);

        if (element && this.elementIsActive(element)) {
          this.processTree(element, (this || _global).addElement);
        }
      }
    };

    ElementObserver.prototype.matchElement = function (element) {
      return (this || _global).delegate.matchElement(element);
    };

    ElementObserver.prototype.matchElementsInTree = function (tree) {
      if (tree === void 0) {
        tree = (this || _global).element;
      }

      return (this || _global).delegate.matchElementsInTree(tree);
    };

    ElementObserver.prototype.processTree = function (tree, processor) {
      for (var _i = 0, _a = this.matchElementsInTree(tree); _i < _a.length; _i++) {
        var element = _a[_i];
        processor.call(this || _global, element);
      }
    };

    ElementObserver.prototype.elementFromNode = function (node) {
      if (node.nodeType == Node.ELEMENT_NODE) {
        return node;
      }
    };

    ElementObserver.prototype.elementIsActive = function (element) {
      if (element.isConnected != (this || _global).element.isConnected) {
        return false;
      } else {
        return (this || _global).element.contains(element);
      }
    };

    ElementObserver.prototype.addElement = function (element) {
      if (!(this || _global).elements.has(element)) {
        if (this.elementIsActive(element)) {
          (this || _global).elements.add(element);

          if ((this || _global).delegate.elementMatched) {
            (this || _global).delegate.elementMatched(element);
          }
        }
      }
    };

    ElementObserver.prototype.removeElement = function (element) {
      if ((this || _global).elements.has(element)) {
        (this || _global).elements.delete(element);

        if ((this || _global).delegate.elementUnmatched) {
          (this || _global).delegate.elementUnmatched(element);
        }
      }
    };

    return ElementObserver;
  }();

  var AttributeObserver = function () {
    function AttributeObserver(element, attributeName, delegate) {
      (this || _global).attributeName = attributeName;
      (this || _global).delegate = delegate;
      (this || _global).elementObserver = new ElementObserver(element, this || _global);
    }

    Object.defineProperty(AttributeObserver.prototype, "element", {
      get: function () {
        return (this || _global).elementObserver.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(AttributeObserver.prototype, "selector", {
      get: function () {
        return "[" + (this || _global).attributeName + "]";
      },
      enumerable: true,
      configurable: true
    });

    AttributeObserver.prototype.start = function () {
      (this || _global).elementObserver.start();
    };

    AttributeObserver.prototype.stop = function () {
      (this || _global).elementObserver.stop();
    };

    AttributeObserver.prototype.refresh = function () {
      (this || _global).elementObserver.refresh();
    };

    Object.defineProperty(AttributeObserver.prototype, "started", {
      get: function () {
        return (this || _global).elementObserver.started;
      },
      enumerable: true,
      configurable: true
    });

    AttributeObserver.prototype.matchElement = function (element) {
      return element.hasAttribute((this || _global).attributeName);
    };

    AttributeObserver.prototype.matchElementsInTree = function (tree) {
      var match = this.matchElement(tree) ? [tree] : [];
      var matches = Array.from(tree.querySelectorAll((this || _global).selector));
      return match.concat(matches);
    };

    AttributeObserver.prototype.elementMatched = function (element) {
      if ((this || _global).delegate.elementMatchedAttribute) {
        (this || _global).delegate.elementMatchedAttribute(element, (this || _global).attributeName);
      }
    };

    AttributeObserver.prototype.elementUnmatched = function (element) {
      if ((this || _global).delegate.elementUnmatchedAttribute) {
        (this || _global).delegate.elementUnmatchedAttribute(element, (this || _global).attributeName);
      }
    };

    AttributeObserver.prototype.elementAttributeChanged = function (element, attributeName) {
      if ((this || _global).delegate.elementAttributeValueChanged && (this || _global).attributeName == attributeName) {
        (this || _global).delegate.elementAttributeValueChanged(element, attributeName);
      }
    };

    return AttributeObserver;
  }();

  function add(map, key, value) {
    fetch(map, key).add(value);
  }

  function del(map, key, value) {
    fetch(map, key).delete(value);
    prune(map, key);
  }

  function fetch(map, key) {
    var values = map.get(key);

    if (!values) {
      values = new Set();
      map.set(key, values);
    }

    return values;
  }

  function prune(map, key) {
    var values = map.get(key);

    if (values != null && values.size == 0) {
      map.delete(key);
    }
  }

  var Multimap = function () {
    function Multimap() {
      (this || _global).valuesByKey = new Map();
    }

    Object.defineProperty(Multimap.prototype, "values", {
      get: function () {
        var sets = Array.from((this || _global).valuesByKey.values());
        return sets.reduce(function (values, set) {
          return values.concat(Array.from(set));
        }, []);
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Multimap.prototype, "size", {
      get: function () {
        var sets = Array.from((this || _global).valuesByKey.values());
        return sets.reduce(function (size, set) {
          return size + set.size;
        }, 0);
      },
      enumerable: true,
      configurable: true
    });

    Multimap.prototype.add = function (key, value) {
      add((this || _global).valuesByKey, key, value);
    };

    Multimap.prototype.delete = function (key, value) {
      del((this || _global).valuesByKey, key, value);
    };

    Multimap.prototype.has = function (key, value) {
      var values = (this || _global).valuesByKey.get(key);

      return values != null && values.has(value);
    };

    Multimap.prototype.hasKey = function (key) {
      return (this || _global).valuesByKey.has(key);
    };

    Multimap.prototype.hasValue = function (value) {
      var sets = Array.from((this || _global).valuesByKey.values());
      return sets.some(function (set) {
        return set.has(value);
      });
    };

    Multimap.prototype.getValuesForKey = function (key) {
      var values = (this || _global).valuesByKey.get(key);

      return values ? Array.from(values) : [];
    };

    Multimap.prototype.getKeysForValue = function (value) {
      return Array.from((this || _global).valuesByKey).filter(function (_a) {
        var key = _a[0],
            values = _a[1];
        return values.has(value);
      }).map(function (_a) {
        var key = _a[0],
            values = _a[1];
        return key;
      });
    };

    return Multimap;
  }();

  var __extends = undefined && undefined.__extends || function () {
    var extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };

    return function (d, b) {
      extendStatics(d, b);

      function __() {
        (this || _global).constructor = d;
      }

      d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
  }();

  var IndexedMultimap = function (_super) {
    __extends(IndexedMultimap, _super);

    function IndexedMultimap() {
      var _this = _super.call(this || _global) || this || _global;

      _this.keysByValue = new Map();
      return _this;
    }

    Object.defineProperty(IndexedMultimap.prototype, "values", {
      get: function () {
        return Array.from((this || _global).keysByValue.keys());
      },
      enumerable: true,
      configurable: true
    });

    IndexedMultimap.prototype.add = function (key, value) {
      _super.prototype.add.call(this || _global, key, value);

      add((this || _global).keysByValue, value, key);
    };

    IndexedMultimap.prototype.delete = function (key, value) {
      _super.prototype.delete.call(this || _global, key, value);

      del((this || _global).keysByValue, value, key);
    };

    IndexedMultimap.prototype.hasValue = function (value) {
      return (this || _global).keysByValue.has(value);
    };

    IndexedMultimap.prototype.getKeysForValue = function (value) {
      var set = (this || _global).keysByValue.get(value);

      return set ? Array.from(set) : [];
    };

    return IndexedMultimap;
  }(Multimap);

  var TokenListObserver = function () {
    function TokenListObserver(element, attributeName, delegate) {
      (this || _global).attributeObserver = new AttributeObserver(element, attributeName, this || _global);
      (this || _global).delegate = delegate;
      (this || _global).tokensByElement = new Multimap();
    }

    Object.defineProperty(TokenListObserver.prototype, "started", {
      get: function () {
        return (this || _global).attributeObserver.started;
      },
      enumerable: true,
      configurable: true
    });

    TokenListObserver.prototype.start = function () {
      (this || _global).attributeObserver.start();
    };

    TokenListObserver.prototype.stop = function () {
      (this || _global).attributeObserver.stop();
    };

    TokenListObserver.prototype.refresh = function () {
      (this || _global).attributeObserver.refresh();
    };

    Object.defineProperty(TokenListObserver.prototype, "element", {
      get: function () {
        return (this || _global).attributeObserver.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(TokenListObserver.prototype, "attributeName", {
      get: function () {
        return (this || _global).attributeObserver.attributeName;
      },
      enumerable: true,
      configurable: true
    });

    TokenListObserver.prototype.elementMatchedAttribute = function (element) {
      this.tokensMatched(this.readTokensForElement(element));
    };

    TokenListObserver.prototype.elementAttributeValueChanged = function (element) {
      var _a = this.refreshTokensForElement(element),
          unmatchedTokens = _a[0],
          matchedTokens = _a[1];

      this.tokensUnmatched(unmatchedTokens);
      this.tokensMatched(matchedTokens);
    };

    TokenListObserver.prototype.elementUnmatchedAttribute = function (element) {
      this.tokensUnmatched((this || _global).tokensByElement.getValuesForKey(element));
    };

    TokenListObserver.prototype.tokensMatched = function (tokens) {
      var _this = this || _global;

      tokens.forEach(function (token) {
        return _this.tokenMatched(token);
      });
    };

    TokenListObserver.prototype.tokensUnmatched = function (tokens) {
      var _this = this || _global;

      tokens.forEach(function (token) {
        return _this.tokenUnmatched(token);
      });
    };

    TokenListObserver.prototype.tokenMatched = function (token) {
      (this || _global).delegate.tokenMatched(token);

      (this || _global).tokensByElement.add(token.element, token);
    };

    TokenListObserver.prototype.tokenUnmatched = function (token) {
      (this || _global).delegate.tokenUnmatched(token);

      (this || _global).tokensByElement.delete(token.element, token);
    };

    TokenListObserver.prototype.refreshTokensForElement = function (element) {
      var previousTokens = (this || _global).tokensByElement.getValuesForKey(element);

      var currentTokens = this.readTokensForElement(element);
      var firstDifferingIndex = zip(previousTokens, currentTokens).findIndex(function (_a) {
        var previousToken = _a[0],
            currentToken = _a[1];
        return !tokensAreEqual(previousToken, currentToken);
      });

      if (firstDifferingIndex == -1) {
        return [[], []];
      } else {
        return [previousTokens.slice(firstDifferingIndex), currentTokens.slice(firstDifferingIndex)];
      }
    };

    TokenListObserver.prototype.readTokensForElement = function (element) {
      var attributeName = (this || _global).attributeName;
      var tokenString = element.getAttribute(attributeName) || "";
      return parseTokenString(tokenString, element, attributeName);
    };

    return TokenListObserver;
  }();

  function parseTokenString(tokenString, element, attributeName) {
    return tokenString.trim().split(/\s+/).filter(function (content) {
      return content.length;
    }).map(function (content, index) {
      return {
        element: element,
        attributeName: attributeName,
        content: content,
        index: index
      };
    });
  }

  function zip(left, right) {
    var length = Math.max(left.length, right.length);
    return Array.from({
      length: length
    }, function (_, index) {
      return [left[index], right[index]];
    });
  }

  function tokensAreEqual(left, right) {
    return left && right && left.index == right.index && left.content == right.content;
  }

  var ValueListObserver = function () {
    function ValueListObserver(element, attributeName, delegate) {
      (this || _global).tokenListObserver = new TokenListObserver(element, attributeName, this || _global);
      (this || _global).delegate = delegate;
      (this || _global).parseResultsByToken = new WeakMap();
      (this || _global).valuesByTokenByElement = new WeakMap();
    }

    Object.defineProperty(ValueListObserver.prototype, "started", {
      get: function () {
        return (this || _global).tokenListObserver.started;
      },
      enumerable: true,
      configurable: true
    });

    ValueListObserver.prototype.start = function () {
      (this || _global).tokenListObserver.start();
    };

    ValueListObserver.prototype.stop = function () {
      (this || _global).tokenListObserver.stop();
    };

    ValueListObserver.prototype.refresh = function () {
      (this || _global).tokenListObserver.refresh();
    };

    Object.defineProperty(ValueListObserver.prototype, "element", {
      get: function () {
        return (this || _global).tokenListObserver.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(ValueListObserver.prototype, "attributeName", {
      get: function () {
        return (this || _global).tokenListObserver.attributeName;
      },
      enumerable: true,
      configurable: true
    });

    ValueListObserver.prototype.tokenMatched = function (token) {
      var element = token.element;
      var value = this.fetchParseResultForToken(token).value;

      if (value) {
        this.fetchValuesByTokenForElement(element).set(token, value);

        (this || _global).delegate.elementMatchedValue(element, value);
      }
    };

    ValueListObserver.prototype.tokenUnmatched = function (token) {
      var element = token.element;
      var value = this.fetchParseResultForToken(token).value;

      if (value) {
        this.fetchValuesByTokenForElement(element).delete(token);

        (this || _global).delegate.elementUnmatchedValue(element, value);
      }
    };

    ValueListObserver.prototype.fetchParseResultForToken = function (token) {
      var parseResult = (this || _global).parseResultsByToken.get(token);

      if (!parseResult) {
        parseResult = this.parseToken(token);

        (this || _global).parseResultsByToken.set(token, parseResult);
      }

      return parseResult;
    };

    ValueListObserver.prototype.fetchValuesByTokenForElement = function (element) {
      var valuesByToken = (this || _global).valuesByTokenByElement.get(element);

      if (!valuesByToken) {
        valuesByToken = new Map();

        (this || _global).valuesByTokenByElement.set(element, valuesByToken);
      }

      return valuesByToken;
    };

    ValueListObserver.prototype.parseToken = function (token) {
      try {
        var value = (this || _global).delegate.parseValueForToken(token);

        return {
          value: value
        };
      } catch (error) {
        return {
          error: error
        };
      }
    };

    return ValueListObserver;
  }();

  var BindingObserver = function () {
    function BindingObserver(context, delegate) {
      (this || _global).context = context;
      (this || _global).delegate = delegate;
      (this || _global).bindingsByAction = new Map();
    }

    BindingObserver.prototype.start = function () {
      if (!(this || _global).valueListObserver) {
        (this || _global).valueListObserver = new ValueListObserver((this || _global).element, (this || _global).actionAttribute, this || _global);

        (this || _global).valueListObserver.start();
      }
    };

    BindingObserver.prototype.stop = function () {
      if ((this || _global).valueListObserver) {
        (this || _global).valueListObserver.stop();

        delete (this || _global).valueListObserver;
        this.disconnectAllActions();
      }
    };

    Object.defineProperty(BindingObserver.prototype, "element", {
      get: function () {
        return (this || _global).context.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(BindingObserver.prototype, "identifier", {
      get: function () {
        return (this || _global).context.identifier;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(BindingObserver.prototype, "actionAttribute", {
      get: function () {
        return (this || _global).schema.actionAttribute;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(BindingObserver.prototype, "schema", {
      get: function () {
        return (this || _global).context.schema;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(BindingObserver.prototype, "bindings", {
      get: function () {
        return Array.from((this || _global).bindingsByAction.values());
      },
      enumerable: true,
      configurable: true
    });

    BindingObserver.prototype.connectAction = function (action) {
      var binding = new Binding((this || _global).context, action);

      (this || _global).bindingsByAction.set(action, binding);

      (this || _global).delegate.bindingConnected(binding);
    };

    BindingObserver.prototype.disconnectAction = function (action) {
      var binding = (this || _global).bindingsByAction.get(action);

      if (binding) {
        (this || _global).bindingsByAction.delete(action);

        (this || _global).delegate.bindingDisconnected(binding);
      }
    };

    BindingObserver.prototype.disconnectAllActions = function () {
      var _this = this || _global;

      (this || _global).bindings.forEach(function (binding) {
        return _this.delegate.bindingDisconnected(binding);
      });

      (this || _global).bindingsByAction.clear();
    };

    BindingObserver.prototype.parseValueForToken = function (token) {
      var action = Action.forToken(token);

      if (action.identifier == (this || _global).identifier) {
        return action;
      }
    };

    BindingObserver.prototype.elementMatchedValue = function (element, action) {
      this.connectAction(action);
    };

    BindingObserver.prototype.elementUnmatchedValue = function (element, action) {
      this.disconnectAction(action);
    };

    return BindingObserver;
  }();

  var Context = function () {
    function Context(module, scope) {
      (this || _global).module = module;
      (this || _global).scope = scope;
      (this || _global).controller = new module.controllerConstructor(this || _global);
      (this || _global).bindingObserver = new BindingObserver(this || _global, (this || _global).dispatcher);

      try {
        (this || _global).controller.initialize();
      } catch (error) {
        this.handleError(error, "initializing controller");
      }
    }

    Context.prototype.connect = function () {
      (this || _global).bindingObserver.start();

      try {
        (this || _global).controller.connect();
      } catch (error) {
        this.handleError(error, "connecting controller");
      }
    };

    Context.prototype.disconnect = function () {
      try {
        (this || _global).controller.disconnect();
      } catch (error) {
        this.handleError(error, "disconnecting controller");
      }

      (this || _global).bindingObserver.stop();
    };

    Object.defineProperty(Context.prototype, "application", {
      get: function () {
        return (this || _global).module.application;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Context.prototype, "identifier", {
      get: function () {
        return (this || _global).module.identifier;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Context.prototype, "schema", {
      get: function () {
        return (this || _global).application.schema;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Context.prototype, "dispatcher", {
      get: function () {
        return (this || _global).application.dispatcher;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Context.prototype, "element", {
      get: function () {
        return (this || _global).scope.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Context.prototype, "parentElement", {
      get: function () {
        return (this || _global).element.parentElement;
      },
      enumerable: true,
      configurable: true
    });

    Context.prototype.handleError = function (error, message, detail) {
      if (detail === void 0) {
        detail = {};
      }

      var _a = this || _global,
          identifier = _a.identifier,
          controller = _a.controller,
          element = _a.element;

      detail = Object.assign({
        identifier: identifier,
        controller: controller,
        element: element
      }, detail);

      (this || _global).application.handleError(error, "Error " + message, detail);
    };

    return Context;
  }();

  var __extends$1 = undefined && undefined.__extends || function () {
    var extendStatics = Object.setPrototypeOf || {
      __proto__: []
    } instanceof Array && function (d, b) {
      d.__proto__ = b;
    } || function (d, b) {
      for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
    };

    return function (d, b) {
      extendStatics(d, b);

      function __() {
        (this || _global).constructor = d;
      }

      d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
  }();

  function blessDefinition(definition) {
    return {
      identifier: definition.identifier,
      controllerConstructor: blessControllerConstructor(definition.controllerConstructor)
    };
  }

  function blessControllerConstructor(controllerConstructor) {
    var constructor = extend(controllerConstructor);
    constructor.bless();
    return constructor;
  }

  var extend = function () {
    function extendWithReflect(constructor) {
      function Controller() {
        var _newTarget = (this || _global) && (this || _global) instanceof Controller ? (this || _global).constructor : void 0;

        return Reflect.construct(constructor, arguments, _newTarget);
      }

      Controller.prototype = Object.create(constructor.prototype, {
        constructor: {
          value: Controller
        }
      });
      Reflect.setPrototypeOf(Controller, constructor);
      return Controller;
    }

    function testReflectExtension() {
      var a = function () {
        (this || _global).a.call(this || _global);
      };

      var b = extendWithReflect(a);

      b.prototype.a = function () {};

      return new b();
    }

    try {
      testReflectExtension();
      return extendWithReflect;
    } catch (error) {
      return function (constructor) {
        return function (_super) {
          __extends$1(Controller, _super);

          function Controller() {
            return _super !== null && _super.apply(this || _global, arguments) || this || _global;
          }

          return Controller;
        }(constructor);
      };
    }
  }();

  var Module = function () {
    function Module(application, definition) {
      (this || _global).application = application;
      (this || _global).definition = blessDefinition(definition);
      (this || _global).contextsByScope = new WeakMap();
      (this || _global).connectedContexts = new Set();
    }

    Object.defineProperty(Module.prototype, "identifier", {
      get: function () {
        return (this || _global).definition.identifier;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Module.prototype, "controllerConstructor", {
      get: function () {
        return (this || _global).definition.controllerConstructor;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Module.prototype, "contexts", {
      get: function () {
        return Array.from((this || _global).connectedContexts);
      },
      enumerable: true,
      configurable: true
    });

    Module.prototype.connectContextForScope = function (scope) {
      var context = this.fetchContextForScope(scope);

      (this || _global).connectedContexts.add(context);

      context.connect();
    };

    Module.prototype.disconnectContextForScope = function (scope) {
      var context = (this || _global).contextsByScope.get(scope);

      if (context) {
        (this || _global).connectedContexts.delete(context);

        context.disconnect();
      }
    };

    Module.prototype.fetchContextForScope = function (scope) {
      var context = (this || _global).contextsByScope.get(scope);

      if (!context) {
        context = new Context(this || _global, scope);

        (this || _global).contextsByScope.set(scope, context);
      }

      return context;
    };

    return Module;
  }();

  var DataMap = function () {
    function DataMap(scope) {
      (this || _global).scope = scope;
    }

    Object.defineProperty(DataMap.prototype, "element", {
      get: function () {
        return (this || _global).scope.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(DataMap.prototype, "identifier", {
      get: function () {
        return (this || _global).scope.identifier;
      },
      enumerable: true,
      configurable: true
    });

    DataMap.prototype.get = function (key) {
      key = this.getFormattedKey(key);
      return (this || _global).element.getAttribute(key);
    };

    DataMap.prototype.set = function (key, value) {
      key = this.getFormattedKey(key);

      (this || _global).element.setAttribute(key, value);

      return this.get(key);
    };

    DataMap.prototype.has = function (key) {
      key = this.getFormattedKey(key);
      return (this || _global).element.hasAttribute(key);
    };

    DataMap.prototype.delete = function (key) {
      if (this.has(key)) {
        key = this.getFormattedKey(key);

        (this || _global).element.removeAttribute(key);

        return true;
      } else {
        return false;
      }
    };

    DataMap.prototype.getFormattedKey = function (key) {
      return "data-" + (this || _global).identifier + "-" + dasherize(key);
    };

    return DataMap;
  }();

  function dasherize(value) {
    return value.replace(/([A-Z])/g, function (_, char) {
      return "-" + char.toLowerCase();
    });
  }

  function attributeValueContainsToken(attributeName, token) {
    return "[" + attributeName + "~=\"" + token + "\"]";
  }

  var TargetSet = function () {
    function TargetSet(scope) {
      (this || _global).scope = scope;
    }

    Object.defineProperty(TargetSet.prototype, "element", {
      get: function () {
        return (this || _global).scope.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(TargetSet.prototype, "identifier", {
      get: function () {
        return (this || _global).scope.identifier;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(TargetSet.prototype, "schema", {
      get: function () {
        return (this || _global).scope.schema;
      },
      enumerable: true,
      configurable: true
    });

    TargetSet.prototype.has = function (targetName) {
      return this.find(targetName) != null;
    };

    TargetSet.prototype.find = function () {
      var targetNames = [];

      for (var _i = 0; _i < arguments.length; _i++) {
        targetNames[_i] = arguments[_i];
      }

      var selector = this.getSelectorForTargetNames(targetNames);
      return (this || _global).scope.findElement(selector);
    };

    TargetSet.prototype.findAll = function () {
      var targetNames = [];

      for (var _i = 0; _i < arguments.length; _i++) {
        targetNames[_i] = arguments[_i];
      }

      var selector = this.getSelectorForTargetNames(targetNames);
      return (this || _global).scope.findAllElements(selector);
    };

    TargetSet.prototype.getSelectorForTargetNames = function (targetNames) {
      var _this = this || _global;

      return targetNames.map(function (targetName) {
        return _this.getSelectorForTargetName(targetName);
      }).join(", ");
    };

    TargetSet.prototype.getSelectorForTargetName = function (targetName) {
      var targetDescriptor = (this || _global).identifier + "." + targetName;
      return attributeValueContainsToken((this || _global).schema.targetAttribute, targetDescriptor);
    };

    return TargetSet;
  }();

  var Scope = function () {
    function Scope(schema, identifier, element) {
      (this || _global).schema = schema;
      (this || _global).identifier = identifier;
      (this || _global).element = element;
      (this || _global).targets = new TargetSet(this || _global);
      (this || _global).data = new DataMap(this || _global);
    }

    Scope.prototype.findElement = function (selector) {
      return this.findAllElements(selector)[0];
    };

    Scope.prototype.findAllElements = function (selector) {
      var head = (this || _global).element.matches(selector) ? [(this || _global).element] : [];
      var tail = this.filterElements(Array.from((this || _global).element.querySelectorAll(selector)));
      return head.concat(tail);
    };

    Scope.prototype.filterElements = function (elements) {
      var _this = this || _global;

      return elements.filter(function (element) {
        return _this.containsElement(element);
      });
    };

    Scope.prototype.containsElement = function (element) {
      return element.closest((this || _global).controllerSelector) === (this || _global).element;
    };

    Object.defineProperty(Scope.prototype, "controllerSelector", {
      get: function () {
        return attributeValueContainsToken((this || _global).schema.controllerAttribute, (this || _global).identifier);
      },
      enumerable: true,
      configurable: true
    });
    return Scope;
  }();

  var ScopeObserver = function () {
    function ScopeObserver(element, schema, delegate) {
      (this || _global).element = element;
      (this || _global).schema = schema;
      (this || _global).delegate = delegate;
      (this || _global).valueListObserver = new ValueListObserver((this || _global).element, (this || _global).controllerAttribute, this || _global);
      (this || _global).scopesByIdentifierByElement = new WeakMap();
      (this || _global).scopeReferenceCounts = new WeakMap();
    }

    ScopeObserver.prototype.start = function () {
      (this || _global).valueListObserver.start();
    };

    ScopeObserver.prototype.stop = function () {
      (this || _global).valueListObserver.stop();
    };

    Object.defineProperty(ScopeObserver.prototype, "controllerAttribute", {
      get: function () {
        return (this || _global).schema.controllerAttribute;
      },
      enumerable: true,
      configurable: true
    });

    ScopeObserver.prototype.parseValueForToken = function (token) {
      var element = token.element,
          identifier = token.content;
      var scopesByIdentifier = this.fetchScopesByIdentifierForElement(element);
      var scope = scopesByIdentifier.get(identifier);

      if (!scope) {
        scope = new Scope((this || _global).schema, identifier, element);
        scopesByIdentifier.set(identifier, scope);
      }

      return scope;
    };

    ScopeObserver.prototype.elementMatchedValue = function (element, value) {
      var referenceCount = ((this || _global).scopeReferenceCounts.get(value) || 0) + 1;

      (this || _global).scopeReferenceCounts.set(value, referenceCount);

      if (referenceCount == 1) {
        (this || _global).delegate.scopeConnected(value);
      }
    };

    ScopeObserver.prototype.elementUnmatchedValue = function (element, value) {
      var referenceCount = (this || _global).scopeReferenceCounts.get(value);

      if (referenceCount) {
        (this || _global).scopeReferenceCounts.set(value, referenceCount - 1);

        if (referenceCount == 1) {
          (this || _global).delegate.scopeDisconnected(value);
        }
      }
    };

    ScopeObserver.prototype.fetchScopesByIdentifierForElement = function (element) {
      var scopesByIdentifier = (this || _global).scopesByIdentifierByElement.get(element);

      if (!scopesByIdentifier) {
        scopesByIdentifier = new Map();

        (this || _global).scopesByIdentifierByElement.set(element, scopesByIdentifier);
      }

      return scopesByIdentifier;
    };

    return ScopeObserver;
  }();

  var Router = function () {
    function Router(application) {
      (this || _global).application = application;
      (this || _global).scopeObserver = new ScopeObserver((this || _global).element, (this || _global).schema, this || _global);
      (this || _global).scopesByIdentifier = new Multimap();
      (this || _global).modulesByIdentifier = new Map();
    }

    Object.defineProperty(Router.prototype, "element", {
      get: function () {
        return (this || _global).application.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Router.prototype, "schema", {
      get: function () {
        return (this || _global).application.schema;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Router.prototype, "controllerAttribute", {
      get: function () {
        return (this || _global).schema.controllerAttribute;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Router.prototype, "modules", {
      get: function () {
        return Array.from((this || _global).modulesByIdentifier.values());
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Router.prototype, "contexts", {
      get: function () {
        return (this || _global).modules.reduce(function (contexts, module) {
          return contexts.concat(module.contexts);
        }, []);
      },
      enumerable: true,
      configurable: true
    });

    Router.prototype.start = function () {
      (this || _global).scopeObserver.start();
    };

    Router.prototype.stop = function () {
      (this || _global).scopeObserver.stop();
    };

    Router.prototype.loadDefinition = function (definition) {
      this.unloadIdentifier(definition.identifier);
      var module = new Module((this || _global).application, definition);
      this.connectModule(module);
    };

    Router.prototype.unloadIdentifier = function (identifier) {
      var module = (this || _global).modulesByIdentifier.get(identifier);

      if (module) {
        this.disconnectModule(module);
      }
    };

    Router.prototype.getContextForElementAndIdentifier = function (element, identifier) {
      var module = (this || _global).modulesByIdentifier.get(identifier);

      if (module) {
        return module.contexts.find(function (context) {
          return context.element == element;
        });
      }
    };

    Router.prototype.handleError = function (error, message, detail) {
      (this || _global).application.handleError(error, message, detail);
    };

    Router.prototype.scopeConnected = function (scope) {
      (this || _global).scopesByIdentifier.add(scope.identifier, scope);

      var module = (this || _global).modulesByIdentifier.get(scope.identifier);

      if (module) {
        module.connectContextForScope(scope);
      }
    };

    Router.prototype.scopeDisconnected = function (scope) {
      (this || _global).scopesByIdentifier.delete(scope.identifier, scope);

      var module = (this || _global).modulesByIdentifier.get(scope.identifier);

      if (module) {
        module.disconnectContextForScope(scope);
      }
    };

    Router.prototype.connectModule = function (module) {
      (this || _global).modulesByIdentifier.set(module.identifier, module);

      var scopes = (this || _global).scopesByIdentifier.getValuesForKey(module.identifier);

      scopes.forEach(function (scope) {
        return module.connectContextForScope(scope);
      });
    };

    Router.prototype.disconnectModule = function (module) {
      (this || _global).modulesByIdentifier.delete(module.identifier);

      var scopes = (this || _global).scopesByIdentifier.getValuesForKey(module.identifier);

      scopes.forEach(function (scope) {
        return module.disconnectContextForScope(scope);
      });
    };

    return Router;
  }();

  var defaultSchema = {
    controllerAttribute: "data-controller",
    actionAttribute: "data-action",
    targetAttribute: "data-target"
  };

  var __awaiter = undefined && undefined.__awaiter || function (thisArg, _arguments, P, generator) {
    return new (P || (P = Promise))(function (resolve, reject) {
      function fulfilled(value) {
        try {
          step(generator.next(value));
        } catch (e) {
          reject(e);
        }
      }

      function rejected(value) {
        try {
          step(generator["throw"](value));
        } catch (e) {
          reject(e);
        }
      }

      function step(result) {
        result.done ? resolve(result.value) : new P(function (resolve) {
          resolve(result.value);
        }).then(fulfilled, rejected);
      }

      step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
  };

  var __generator = undefined && undefined.__generator || function (thisArg, body) {
    var _ = {
      label: 0,
      sent: function () {
        if (t[0] & 1) throw t[1];
        return t[1];
      },
      trys: [],
      ops: []
    },
        f,
        y,
        t,
        g;
    return g = {
      next: verb(0),
      throw: verb(1),
      return: verb(2)
    }, typeof Symbol === "function" && (g[Symbol.iterator] = function () {
      return this || _global;
    }), g;

    function verb(n) {
      return function (v) {
        return step([n, v]);
      };
    }

    function step(op) {
      if (f) throw new TypeError("Generator is already executing.");

      while (_) try {
        if (f = 1, y && (t = y[op[0] & 2 ? "return" : op[0] ? "throw" : "next"]) && !(t = t.call(y, op[1])).done) return t;
        if (y = 0, t) op = [0, t.value];

        switch (op[0]) {
          case 0:
          case 1:
            t = op;
            break;

          case 4:
            _.label++;
            return {
              value: op[1],
              done: false
            };

          case 5:
            _.label++;
            y = op[1];
            op = [0];
            continue;

          case 7:
            op = _.ops.pop();

            _.trys.pop();

            continue;

          default:
            if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) {
              _ = 0;
              continue;
            }

            if (op[0] === 3 && (!t || op[1] > t[0] && op[1] < t[3])) {
              _.label = op[1];
              break;
            }

            if (op[0] === 6 && _.label < t[1]) {
              _.label = t[1];
              t = op;
              break;
            }

            if (t && _.label < t[2]) {
              _.label = t[2];

              _.ops.push(op);

              break;
            }

            if (t[2]) _.ops.pop();

            _.trys.pop();

            continue;
        }

        op = body.call(thisArg, _);
      } catch (e) {
        op = [6, e];
        y = 0;
      } finally {
        f = t = 0;
      }

      if (op[0] & 5) throw op[1];
      return {
        value: op[0] ? op[1] : void 0,
        done: true
      };
    }
  };

  var Application = function () {
    function Application(element, schema) {
      if (element === void 0) {
        element = document.documentElement;
      }

      if (schema === void 0) {
        schema = defaultSchema;
      }

      (this || _global).element = element;
      (this || _global).schema = schema;
      (this || _global).dispatcher = new Dispatcher(this || _global);
      (this || _global).router = new Router(this || _global);
    }

    Application.start = function (element, schema) {
      var application = new Application(element, schema);
      application.start();
      return application;
    };

    Application.prototype.start = function () {
      return __awaiter(this || _global, void 0, void 0, function () {
        return __generator(this || _global, function (_a) {
          switch (_a.label) {
            case 0:
              return [4, domReady()];

            case 1:
              _a.sent();

              (this || _global).router.start();

              (this || _global).dispatcher.start();

              return [2];
          }
        });
      });
    };

    Application.prototype.stop = function () {
      (this || _global).router.stop();

      (this || _global).dispatcher.stop();
    };

    Application.prototype.register = function (identifier, controllerConstructor) {
      this.load({
        identifier: identifier,
        controllerConstructor: controllerConstructor
      });
    };

    Application.prototype.load = function (head) {
      var _this = this || _global;

      var rest = [];

      for (var _i = 1; _i < arguments.length; _i++) {
        rest[_i - 1] = arguments[_i];
      }

      var definitions = Array.isArray(head) ? head : [head].concat(rest);
      definitions.forEach(function (definition) {
        return _this.router.loadDefinition(definition);
      });
    };

    Application.prototype.unload = function (head) {
      var _this = this || _global;

      var rest = [];

      for (var _i = 1; _i < arguments.length; _i++) {
        rest[_i - 1] = arguments[_i];
      }

      var identifiers = Array.isArray(head) ? head : [head].concat(rest);
      identifiers.forEach(function (identifier) {
        return _this.router.unloadIdentifier(identifier);
      });
    };

    Object.defineProperty(Application.prototype, "controllers", {
      get: function () {
        return (this || _global).router.contexts.map(function (context) {
          return context.controller;
        });
      },
      enumerable: true,
      configurable: true
    });

    Application.prototype.getControllerForElementAndIdentifier = function (element, identifier) {
      var context = (this || _global).router.getContextForElementAndIdentifier(element, identifier);

      return context ? context.controller : null;
    };

    Application.prototype.handleError = function (error, message, detail) {
      console.error("%s\n\n%o\n\n%o", message, error, detail);
    };

    return Application;
  }();

  function domReady() {
    return new Promise(function (resolve) {
      if (document.readyState == "loading") {
        document.addEventListener("DOMContentLoaded", resolve);
      } else {
        resolve();
      }
    });
  }

  function defineTargetProperties(constructor) {
    var prototype = constructor.prototype;
    var targetNames = getTargetNamesForConstructor(constructor);
    targetNames.forEach(function (name) {
      var _a;

      return defineLinkedProperties(prototype, (_a = {}, _a[name + "Target"] = {
        get: function () {
          var target = (this || _global).targets.find(name);

          if (target) {
            return target;
          } else {
            throw new Error("Missing target element \"" + (this || _global).identifier + "." + name + "\"");
          }
        }
      }, _a[name + "Targets"] = {
        get: function () {
          return (this || _global).targets.findAll(name);
        }
      }, _a["has" + capitalize(name) + "Target"] = {
        get: function () {
          return (this || _global).targets.has(name);
        }
      }, _a));
    });
  }

  function getTargetNamesForConstructor(constructor) {
    var ancestors = getAncestorsForConstructor(constructor);
    return Array.from(ancestors.reduce(function (targetNames, constructor) {
      getOwnTargetNamesForConstructor(constructor).forEach(function (name) {
        return targetNames.add(name);
      });
      return targetNames;
    }, new Set()));
  }

  function getAncestorsForConstructor(constructor) {
    var ancestors = [];

    while (constructor) {
      ancestors.push(constructor);
      constructor = Object.getPrototypeOf(constructor);
    }

    return ancestors;
  }

  function getOwnTargetNamesForConstructor(constructor) {
    var definition = constructor["targets"];
    return Array.isArray(definition) ? definition : [];
  }

  function defineLinkedProperties(object, properties) {
    Object.keys(properties).forEach(function (name) {
      if (!(name in object)) {
        var descriptor = properties[name];
        Object.defineProperty(object, name, descriptor);
      }
    });
  }

  function capitalize(name) {
    return name.charAt(0).toUpperCase() + name.slice(1);
  }

  var Controller = function () {
    function Controller(context) {
      (this || _global).context = context;
    }

    Controller.bless = function () {
      defineTargetProperties(this || _global);
    };

    Object.defineProperty(Controller.prototype, "application", {
      get: function () {
        return (this || _global).context.application;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Controller.prototype, "scope", {
      get: function () {
        return (this || _global).context.scope;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Controller.prototype, "element", {
      get: function () {
        return (this || _global).scope.element;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Controller.prototype, "identifier", {
      get: function () {
        return (this || _global).scope.identifier;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Controller.prototype, "targets", {
      get: function () {
        return (this || _global).scope.targets;
      },
      enumerable: true,
      configurable: true
    });
    Object.defineProperty(Controller.prototype, "data", {
      get: function () {
        return (this || _global).scope.data;
      },
      enumerable: true,
      configurable: true
    });

    Controller.prototype.initialize = function () {};

    Controller.prototype.connect = function () {};

    Controller.prototype.disconnect = function () {};

    Controller.targets = [];
    return Controller;
  }();

  exports.Application = Application;
  exports.Context = Context;
  exports.Controller = Controller;
  exports.defaultSchema = defaultSchema;
  Object.defineProperty(exports, "__esModule", {
    value: true
  });
});

export default exports;
export const Application = exports.Application,
      Context = exports.Context,
      Controller = exports.Controller,
      defaultSchema = exports.defaultSchema,
      __esModule = exports.__esModule;